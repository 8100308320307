import React from "react";
import classes from "./Status.module.css";

const Status = ({ status }) => {
  return (
    <span className={classes[status]}>
      {status === "pending" ? "Pending" : "Active"}
    </span>
  );
};

export default Status;
