import axios from 'axios';
import { API_BASE_URL, getAxiosConfig, AxiosConfigs } from '../../config';

const getUrl = (url) => {
    return API_BASE_URL + '/api/' + url
}

export const getPreviewForm = async (formId) => {
    try {
        return await axios.post(getUrl('getForm'), { formId });
    } catch (error) {
        window.location.href = window.location.origin + '/'
    }
};

export const getResponseLimitValue = async (formId) => {
    try {
        return await axios.post(getUrl('getResponseLimitValue'), { formId });
    } catch (error) {
        window.location.href = window.location.origin + '/'
    }
};

export const getMyFormsInfo = async () => {
    try {
        return await axios.post(getUrl('getForms'), null, AxiosConfigs);
    } catch (error) {
        console.error(error.message);
    }
};

export const getMyTrashedFormsInfo = async () => {
    try {
        return await axios.post(getUrl('getTrashedForms'), null, AxiosConfigs);
    } catch (error) {
        console.error(error.message);
    }
};
export const getMyInfo = async () => {
    try {
        return await axios.post(getUrl('getMe'), null, AxiosConfigs);
    } catch (error) {
        if (error.message) {
            // localStorage.removeItem('auth')
            // localStorage.removeItem('authUser')
            // localStorage.removeItem('authAdmin')
            // window.location.href = window.location.origin + '/'
            console.error(error.message);
        }
    }
};
export const getAdminInfo = async () => {
    try {
        return await axios.post(getUrl('getAdminInfo'), null, getAxiosConfig());
    } catch (error) {
        // if (error.message) {
        //  localStorage.removeItem('auth')
        //  localStorage.removeItem('authUser')
        //  localStorage.removeItem('authAdmin')
        //  window.location.href = window.location.href + '/'
        // }
        console.error(error.message);
    }
};
export const getErrorLogs = async () => {
    try {
        return await axios.post(getUrl('getErrorLogs'), null, AxiosConfigs);
    } catch (error) {
        console.error(error.message);
    }
};

export const getNotificationsInfo = async () => {
    try {
        return await axios.post(getUrl('getNotifications'), null, AxiosConfigs);
    } catch (error) {
        // console.error(error.message);
    }
};

export const getMyGeneralInfo = async () => {
    try {
        return await axios.post(getUrl('GetMyGeneralDetails'), null, AxiosConfigs);
    } catch (error) {
        console.error(error.message);
    }
};

export const getResponsesAllInfo = async (formId) => {
    try {
        return await axios.post(getUrl('getResponses?page=' + formId['page']), { formId }, AxiosConfigs);
    } catch (error) {
        console.error(error.message);
        window.location.href = window.location.origin + '/'
    }
};

export const getTrashedResponsesAllInfo = async (formId) => {
    try {
        return await axios.post(getUrl('getTrashedResponses'), { formId });
    } catch (error) {
        console.error(error.message);
    }
};

export const getMembersInfo = async (page) => {
    try {
        return await axios.post(getUrl('getMembers?page=' + page.page), null, AxiosConfigs);
    } catch (error) {
        console.error(error.message);
    }
};

export const getMembersConfirmInfo = async (page) => {
    try {
        return await axios.post(getUrl('getMembersConfirm?page=' + page.page), null, AxiosConfigs);
    } catch (error) {
        console.error(error.message);
    }
};

export const getMembersConfirmCountInfo = async () => {
    try {
        return await axios.post(getUrl('getMembersConfirmCount'), null, AxiosConfigs);
    } catch (error) {
        console.error(error.message);
    }
};

export const getResponsesFullInfo = async () => {
    try {
        return await axios.post(getUrl('getResponsesDatas'));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdmPayingInfo = async () => {
    try {
        return await axios.post(getUrl('getPayingInfo'));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdmPayingInAllInfo = async (data) => {
    try {
        return await axios.post(getUrl('getPayingInAllInfo'), data);
    } catch (error) {
        console.error(error.message);
    }
};

export const getUsersInfo = async () => {
    try {
        return await axios.post(getUrl('getUserDatas'));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdminUsersInfo = async (data) => {
    try {
        return await axios.post(getUrl('getAdminUserDatas?page=' + data.page), data);
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdmNotSolvedMessageInfo = async (page) => {
    try {
        return await axios.post(getUrl('getNotSolvedMessageInfo?page=' + page.page));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdmSolvedMessageInfo = async (page) => {
    try {
        return await axios.post(getUrl('getSolvedMessageInfo?page=' + page.page));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdmNotSolvedPaymentInfo = async (page) => {
    try {
        return await axios.post(getUrl('getNotSolvedPaymentInfo?page=' + page.page));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdmSolvedPaymentInfo = async (page) => {
    try {
        return await axios.post(getUrl('getSolvedPaymentInfo?page=' + page.page));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdmPaymentInfo = async (page) => {
    try {
        return await axios.post(getUrl('getPaymentInfo?page=' + page.page));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdmNotSolvedCustomerInfo = async (page) => {
    try {
        return await axios.post(getUrl('getNotSolvedCustomerInfo?page=' + page.page));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdmSuspendedInfo = async (page) => {
    try {
        return await axios.post(getUrl('getSuspendedInfo?page=' + page.page));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdmSolvedCustomerInfo = async (page) => {
    try {
        return await axios.post(getUrl('getSolvedCustomerInfo?page=' + page.page));
    } catch (error) {
        console.error(error.message);
    }
};
export const getAdminFormInfo = async (page) => {
    try {
        return await axios.post(getUrl('getFormsDatasForAdmin?page=' + page.page));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdminMessagesInfo = async (page) => {
    try {
        return await axios.post(getUrl('getMessagesDatasForAdmin?page=' + page.page));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdminCustomersInfo = async (page) => {
    try {
        return await axios.post(getUrl('getCustomersDatasForAdmin?page=' + page.page));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdminDataByMonthInfo = async () => {
    try {
        return await axios.post(getUrl('getDataByMonth'));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdminResponsesInfo = async (page) => {
    try {
        return await axios.post(getUrl('getResponsesDatasForAdmin?page=' + page.page));
    } catch (error) {
        console.error(error.message);
    }
};

export const getFormsInfo = async () => {
    try {
        return await axios.post(getUrl('getFormsDatas'));
    } catch (error) {
        console.error(error.message);
    }
};

export const getResponsesMonthInfo = async (formId) => {
    try {
        return await axios.post(getUrl('getResponsesMonth'), { formId });
    } catch (error) {
        console.error(error.message);
    }
};

export const getResponsesTodayInfo = async (formId) => {
    try {
        return await axios.post(getUrl('getResponsesToday'), { formId });
    } catch (error) {
        console.error(error.message);
    }
};

export const getResponsesYearInfo = async (formId) => {
    try {
        return await axios.post(getUrl('getResponsesYear'), { formId });
    } catch (error) {
        console.error(error.message);
    }
};

export const getResponsesFullByMonthInfo = async () => {
    try {
        return await axios.post(getUrl('getResponsesFullByMonth'));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdminFormReportsInfo = async (page) => {
    try {
        return await axios.post(getUrl('getReportsForAdmin?page=' + page.page));
    } catch (error) {
        console.error(error.message);
    }
};

export const getAdminSuggestionsInfo = async (page) => {
    try {
        return await axios.post(getUrl('getSuggestionsForAdmin?page=' + page.page));
    } catch (error) {
        console.error(error.message);
    }
};

// Team API

export const getTeamMembersAPI = async () => {
    return await axios.get(getUrl("get-team-members"), AxiosConfigs);
  };
  
  export const getExternalFormsAPI = async () => {
    return await axios.get(getUrl("get-external-forms"), AxiosConfigs);
  };
  
  export const addMemberToTheTeamAPI = async (data) => {
    return await axios.post(getUrl("add-member-to-the-team"), data, AxiosConfigs);
  };
  
  export const deleteTeamMemberAPI = async (user_id) => {
    return await axios.delete(
      getUrl(`delete-team-member?user_id=${user_id}`),
      AxiosConfigs
    );
  };

  export const cancelInvitationAPI = async (invitation_id) => {
    return await axios.delete(
      getUrl(`invitations/remove?invitation_id=${invitation_id}`),
      AxiosConfigs
    );
  };
