import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context";
import FormData from "form-data";
import axios from "axios";
import { useParams, useLocation } from "react-router";
import { API_BASE_URL, APP_BASE_URL } from "../config";
import Loader from "../UI/Loader/Loader";
import ApproveInvitationForm from "../components/ApproveInvitationForm";

const ApproveInvitation = () => {
  const [signupData, setSignupData] = useState({ confirm: true });
  const [errors, setErrors] = useState([]);
  const { setIsAuth, setIsAuthAdmin } = useContext(AuthContext);

  const params = useParams();
  const signupType = params.type ? params.type : "";
  const [loading, setLoading] = useState(false);

  const { token } = useParams();
  const query = new URLSearchParams(useLocation().search);

  const firstname = query.get("firstname");
  const lastname = query.get("lastname");
  const defaultEmail = query.get("email");
  const workspaceId = query.get("workspace_id");

  useEffect(() => {
    if (defaultEmail) {
      setSignupData((prevValue) => {
        return { ...prevValue, email: defaultEmail };
      });
    }
  }, [defaultEmail]);

  const accept = (e) => {
    setLoading(true);
    e.preventDefault();
    let data = new FormData();

    for (var key in signupData) {
      data.append(key, signupData[key]);
    }

    if (signupType !== "") {
      data.append("signupType", signupType);
    }

    data.append("workspace_id", workspaceId);
    data.append("auto_verify", true);

    axios
      .post(`${API_BASE_URL}/api/approve-invitation/${token}`, data)
      .then((response) => {
        setLoading(false);

        const token = response.data.access_token;

        localStorage.setItem("auth", "true");
        localStorage.setItem("authUser", token);
        if (localStorage.getItem("authAdmin")) {
          localStorage.removeItem("authAdmin");
          setIsAuthAdmin(false);
        }
        setIsAuth(true);
        window.location.href = APP_BASE_URL + "/forms";
      })
      .catch((error) => {
        setLoading(false);
        setErrors(error.response.data["errors"]);
      });
  };

  return (
    <div
      className="signup-template bg-gray-100"
      style={{ backgroundColor: "#fff" }}
    >
      <div className="position-absolute top-0 start-0 p-4 p-md-5">
        <a href="http://formbuilder.ai/" className="ps-lg-3">
          <img width="250" src="/logo-final.png" alt="FormBuilder" />
        </a>
      </div>
      <div className="row g-0 align-items-center">
        <div
          className="col-lg-5"
          style={{ textAlign: "center", backgroundColor: "#FBF3E5" }}
        >
          <img
            src="/img/BG/sign-in.svg"
            className="cover-fit"
            alt="Sign in Cover"
          />
        </div>
        <div className="col-lg-7 px-md-3 px-xl-5">
          <div>
            {loading ? (
              <div className="signupForm-loader">
                <Loader />
              </div>
            ) : null}

            <div className="px-3 py-4 p-md-5 p-xxl-5 mx-xxl-4">
              <div
                className="login-form py-2 py-md-0 mx-auto mx-lg-0"
                style={{ margin: "auto" }}
              >
                <h2 className="h1 mb-2">Accept Invitation</h2>
                <div className="position-relative">
                  <hr className="bg-gray-200 border-gray-200 opacity-100" />
                  <span className="position-absolute top-0 start-50 translate-middle text-gray-600 small bg-gray-100 px-2 px-xxl-4 text-nowrap">
                    From {firstname} {lastname}
                  </span>
                </div>
                <ApproveInvitationForm
                  defaultEmail={defaultEmail}
                  signupData={signupData}
                  setSignupData={setSignupData}
                  accept={accept}
                  errors={errors}
                  setErrors={setErrors}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveInvitation;
