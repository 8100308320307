import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTeamMembersAPI,
  getExternalFormsAPI,
  addMemberToTheTeamAPI,
  deleteTeamMemberAPI,
  cancelInvitationAPI,
} from "../features/dataApi";

const initialState = {
  loading: false,
  teamMembers: undefined,
  externalForms: undefined,
  error: null,
};

export const getTeamMembers = createAsyncThunk(
  "/api/get-team-members",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getTeamMembersAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getExternalForms = createAsyncThunk(
  "/api/get-external-forms",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getExternalFormsAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addMemberToTheTeam = createAsyncThunk(
  "/api/add-member-to-the-team",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addMemberToTheTeamAPI(data);

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data.message ||
          error.response?.data.error?.email ||
          error.response?.data.error?.role
      );
    }
  }
);

export const deleteTeamMember = createAsyncThunk(
  "/api/delete-team-member",
  async (user_id, { rejectWithValue }) => {
    try {
      const response = await deleteTeamMemberAPI(user_id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const cancelInvitation = createAsyncThunk(
  "/api/cancel-invitation",
  async (invitation_id, { rejectWithValue }) => {
    try {
      const response = await cancelInvitationAPI(invitation_id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const teamSlice = createSlice({
  name: "teamReducer",
  initialState,
  reducers: {
    setTeamMembers: (state, action) => {
      state.teamMembers = action.payload;
    },
    setExternalForms: (state, action) => {
      state.externalForms = action.payload;
    },
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamMembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTeamMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.teamMembers = action.payload;
      })
      .addCase(getTeamMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getExternalForms.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getExternalForms.fulfilled, (state, action) => {
        state.loading = false;
        state.externalForms = action.payload;
      })
      .addCase(getExternalForms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(addMemberToTheTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addMemberToTheTeam.fulfilled, (state, action) => {
        state.loading = false;
        if (state.teamMembers === undefined) {
          state.teamMembers = [action.payload.member];
        } else {
          state.teamMembers.push(action.payload.member);
        }
      })
      .addCase(addMemberToTheTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(deleteTeamMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTeamMember.fulfilled, (state, action) => {
        state.loading = false;

        state.teamMembers = state.teamMembers.filter(
          (member) => member._id !== action.payload.user_id
        );
      })
      .addCase(deleteTeamMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(cancelInvitation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(cancelInvitation.fulfilled, (state, action) => {
        state.loading = false;
        state.teamMembers = state.teamMembers.filter(
          (member) => member._id !== action.payload.invitation_id
        );
      })
      .addCase(cancelInvitation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setTeamMembers, setExternalForms, resetError } =
  teamSlice.actions;

export const getTeamMembersData = (state) => state.teamReducer.teamMembers;
export const getTeamMembersLoading = (state) => state.teamReducer.loading;
export const getExternalFormsData = (state) => state.teamReducer.externalForms;
export const getTeamMembersError = (state) => state.teamReducer.error;

export default teamSlice.reducer;
