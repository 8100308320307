import React from "react";
import classes from "./TeamMemberCard.module.css";
import { API_BASE_URL } from "../../config";
import {
  cancelInvitation,
  deleteTeamMember,
} from "../../redux/reducers/teamReducer";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Status from "./Status";
import { getInfoData } from "../../redux/reducers/infoReducer";

const TeamMemberCard = ({ member }) => {
  const { loading } = useSelector((state) => state.teamReducer);
  const dispatch = useDispatch();

  const myInfo = useSelector(getInfoData);

  if (!member) return;

  const deleteMember = () => {
    Swal.fire({
      title: `Are you sure you want to delete member <strong class="${
        classes.fullname
      }">${member.fullName || ""}</strong>?`,
      text: "This member will be removed and it will not be possible to restore",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      customClass: {
        cancelButton: "btn-outline-dark-default",
      },
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteTeamMember(member._id));
      }
    });
  };

  const cancelInvite = () => {
    Swal.fire({
      title: `Are you sure you want cancel invitation for <strong class="${
        classes.fullname
      }">${member.email || ""}</strong>?`,
      text: "This invitation will be canceled and removed from the invitee list",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      customClass: {
        cancelButton: "btn-outline-dark-default",
      },
      confirmButtonText: "Cancel Invitation",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(cancelInvitation(member._id));
      }
    });
  };

  return (
    <>
      <div className={classes["main-container"]}>
        <div className={classes["info-container"]}>
          <div className={classes["profile-block"]}>
            <span className="avatar avatar-sm avatar-circle">
              {member.status === "accepted" ? (
                member.avatar === "firstname" ? (
                  member.firstname ? (
                    <div
                      className="avatar-div"
                      style={{ backgroundColor: member.color }}
                    >
                      <p className="avatar-letter avatar-letter-small">
                        {member.firstname?.[0]}
                      </p>
                    </div>
                  ) : (
                    <div
                      className="avatar-div"
                      style={{ backgroundColor: member.color }}
                    >
                      <p className="avatar-letter avatar-letter-small">
                        {member.email[0]}
                      </p>
                    </div>
                  )
                ) : (
                  <img
                    className="avatar-img"
                    src={
                      API_BASE_URL +
                      `/files/User/${member._id}/${member.avatar}`
                    }
                    alt="Avatar"
                  />
                )
              ) : (
                <div
                  className="avatar-div"
                  style={{ backgroundColor: "#FD4949" }}
                >
                  <p className="avatar-letter avatar-letter-small">
                    {member.email[0]}
                  </p>
                </div>
              )}
              {member.status === "pending"}
            </span>
            <div className={classes["info-label"]}>
              {member.fullName && <span>{member.fullName}</span>}
              <span className={classes.email}>{member.email}</span>
            </div>
          </div>
          <Status status={member.status.toLowerCase()} />
        </div>
        <button
          onClick={() =>
            member.status === "accepted" ? deleteMember() : cancelInvite()
          }
          className="btn form-link-button"
          disabled={loading || myInfo._id === member._id}
        >
          <span>
            <i className="fas fa-trash"></i>
          </span>
        </button>
      </div>
    </>
  );
};

export default TeamMemberCard;
