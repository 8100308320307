import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE_URL, APP_BASE_URL } from '../config';
import { AuthContext } from '../context';
import SigninForm from './SigninForm';
import SigninFormGoogle from './SigninFormGoogle';
import { useParams } from 'react-router';
import { getCookie, setCookie, eraseCookie } from '../cookie';
import Loader from '../UI/Loader/Loader';
import jwt from 'jsonwebtoken'

const TestActions = () => {

	const updateUsers = async (e) => {}

	return (

		<div className="signup-template bg-gray-100" style={{ backgroundColor: '#fff'}}>
			{/* <button onClick={() => updateUsers()}>Update Users</button> */}
		</div>
	);
};

export default TestActions;